import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://49bed617303040ae988bb271ef886483@o555412.ingest.us.sentry.io/5685898",
  debug: false,
  environment: "development",
  release: "6caeed8f47c73020f01b2ef29942a1c9c54e545a",
  tracesSampleRate: 1,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});